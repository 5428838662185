<template>
        <v-sheet
        class="d-flex rounded-0 text-dark-surface justify-space-between position-absolute backdrop-blur" style=" z-index:2000; width: 100vw;" >
            <div class="d-flex justify-start align-center ms-4 " style="min-width: 100px;" 
            @click="scrollToTop">
                <v-img
                    src="@/assets/logo.webp"
                    alt="Logo ORIAdev"
                    max-width="35" min-width="35"></v-img>
                <span class="title-text mt-1 text-dark-surface">ORIA<span class="thin">dev</span></span>

                <!--<v-img
                    src="@/assets/bubbles.png"
                    class="mt-2"
                    max-width="45" min-width="45"></v-img>-->
            </div>
            <div class="d-flex" v-if="mdAndUp">
                <!--
                <v-tabs
                class="pe-4 ps-4"
                v-model="tab"
                align-tabs="end"
                color="primary"
                fixed-tabs
                height="100%"
                >
                    <v-tab v-for="item in items" :key="item.val" :value="item.val" :ripple="false">
                        {{ item.title }}</v-tab>
                </v-tabs>-->
                <v-btn  aria-label="Contact button" color="primary" class="rounded-0 ma-0 elevation-0 rounded-s-0 pa-6 pa-md-4" height="100%" @click="showContactPopup">{{ content.metadata.contactButton }}</v-btn>
            </div>
            <div v-else><v-btn icon="mdi-dots-vertical" variant="text" aria-label="Options" @click="drawer = !drawer"></v-btn>
            </div>
        </v-sheet>
        <v-navigation-drawer 
            v-if="!mdAndUp"
                v-model="drawer"
                location="right"
                temporary
                color="dark-surface"
            >
            <div class="pt-16">
                <!--
                    <v-btn v-for="item in items" :key="item.val" :value="item.val" @click="tab = item.val" class="rounded-0 ma-0 elevation-0 rounded-s-0" :class="tab === item.val ? 'text-primary' : ''"
            width="100%" color="transparent">{{item.title }}</v-btn>
            
                -->
            <v-btn  aria-label="Contact button" color="primary" class="rounded-0 ma-0 elevation-0 rounded-s-0" width="100%" @click="showContactPopup">{{ content.metadata.contactButton }}</v-btn>
                </div>
            </v-navigation-drawer>
 
</template>
<script>
import { mapActions } from 'vuex';
import { useDisplay } from 'vuetify'
import { useGoTo } from 'vuetify'

    export default {
        setup() {
            const { xs, mdAndUp } = useDisplay()
            const goTo = useGoTo()

            return {
            xs, mdAndUp, goTo
            }
        },
        name: 'NavBarHome',
        props: {
            content: Object
        },
        data: () => ({
        tab: 1,
        items: [
            { title: 'Accueil', icon: 'mdi-home', val:1 },
            { title: 'Blog', icon: 'mdi-newspaper', val:2 },
        ],
        drawer: false,
        }),
        watch: {
        tab (val) {
            if (val === 1) {
            this.$router.push({ name: 'home' })
            } else if (val === 2) {
            this.$router.push({ name: 'blog' })
            }
        },
        $route (to) {
            if (to.name === 'home') {
            this.tab = 1
            } else if (to.name === 'blog') {
            this.tab = 2
            }
        }
        },
        methods: {
    ...mapActions(['showContactPopup']),
    scrollToTop() {
        console.log('scrollToTop')
        this.goTo(0, {container: '#mainContainer'});
    }
}


    }
  </script>


<style>
.title-text {
    font-family: 'Roboto Serif', serif;
    font-weight: 500; /* Medium */
    font-size: 28px; /* Adjust as needed */
    padding-left: 8px;
    /*Unselectable text*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.title-text .thin {
    font-weight: 200; /* Thin */
}
</style>