<template>
    <v-card :class="`bg-${color} rounded-xl elevation-0 fill-height text-${textcolor}`" width="100%" :style="outline ? 'border: 1px solid ' + outline : ''">
        <v-img :src="image" aspect-ratio="1.5" cover v-if="image != null"></v-img>
        <v-card-item class="pa-4 pb-4 pa-md-6 pa-xl-12" :class="title ? 'pb-md-2 pb-xl-5' : 'pb-md-0 pb-xl-0'">
        <v-card-title class="scale-up-title" v-if="title" >{{ title }}</v-card-title>
        </v-card-item>
        <v-card-text class="pa-4 pt-0 pa-md-6 pt-md-0 pa-xl-12 pt-xl-0 ">
        <div class="scale-text">
            <slot></slot>
        </div>
        </v-card-text>
    </v-card> 
</template>

<script>
export default {
    name: 'MainSectionCard',
    props: {
        //
        color: String,
        title: {
            type: String,
            default: null,
        },
        // image with default null value
        image: {
            type: String,
            default: null,
        },
        outline: {
            type: String,
            default: null,
        },
        textcolor: {
            type: String,
            default: 'dark-surface',
        },
    },
    data: () => ({
        //
    }),
}
</script>