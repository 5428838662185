<template>
    <v-sheet class="bg-surface position-relative" style="min-width:100vw; overflow-x: hidden;">


    <v-container class="bg-surface ma-0 pa-0 ps-2 pe-2 pt-16 ps-md-8 pt-md-16 ps-xl-16 pt-xl-16 fluid" style="min-width: 100%;">
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="9"
      >
      <v-container class="bg-surface pa-0 pt-0 ma-0 pa-md-4 pa-xl-8 fluid text-dark-surface" style="width:100%;">
        <v-row no-gutters>
          <v-col
            class=" pt-8 pb-8 ps-md-8 pb-md-8 pt-md-16 ps-xl-16 pb-xl-8 pt-xl-16"
            cols="12"
            sm="12"
          >
          <div class="d-flex flex-column pe-4" style="width: 100%">
            <h1 class="hero-quote text-dark-surface ps-2 ps-md-6 ps-xl-4" :class="mdAndUp?'':'d-flex flex-column'">
              <span v-html="content.heroSection.title"></span>
  <span class="text-primary" id="animated-word" style="white-space: nowrap;" v-html="content.heroSection.titleWords[0]"></span>
</h1>
<span class="scribble align-self-center pe-0 ps-md-8 ps-xl-16"><span style="font-weight: 300;">↖ </span><span @click="goTo('#values', {container: '#mainContainer', duration: 300, offset: -100 })" v-html="content.heroSection.subtitle" class="link"></span></span>
          </div>

          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            class=" pt-0 pb-0 pb-md-4 pt-md-2 ps-md-8 pb-xl-4 pt-xl-8 ps-xl-16"
            cols="12"
            sm="12"
          >
          <!--38vw on large screen, 100vw on-->
          <div class="scale-up-text ps-2 ps-md-8 ps-xl-16 pt-xl-1" :style="mdAndUp ? 'max-width: 49vw;' : 'max-width: 100vw;'" style="font-size: 1.1rem;" v-html="content.heroSection.textHtml">
            </div>

          </v-col>
        </v-row>
        <v-row
            v-if="!mdAndUp" no-gutters>
          <v-col
          
          class="d-flex justify-center pt-0 pb-0 pa-16"
            cols="12"
            xs="4"
            sm="4"

          >
          <v-img
            src="@/assets/images/bird8.webp"
            alt="Free flying red bird"
            aspect-ratio="0.821323"
            cover
            style="max-width: 50vw;"
          ></v-img>
          </v-col>
        </v-row>
        <v-row class="pa-2 pt-0 pa-md-16 pt-md-4 pa-xl-16 pt-xl-8" no-gutters v-if="mdAndUp">
          <v-col
            cols="12"
            xs="12"
            sm="6"
              v-for="(slide, i) in content.heroSection.cards"
              :key="i"
          >
          <ColoredCard :slide="slide"></ColoredCard>
          </v-col>
        </v-row>
        <v-row class=" pa-2 pt-0 pa-md-8 pt-md-4 pa-xl-16 pt-xl-8" no-gutters v-else>
          <v-col
            cols="12"
            xs="12"
            sm="6"
          >
            <v-window v-model="currentSlide">
              <v-window-item :value="i" v-for="(slide, i) in content.heroSection.cards" :key="i">
                <ColoredCard :slide="slide"></ColoredCard>
              </v-window-item>
            </v-window>
            <v-item-group
              v-model="onboarding"
              class="text-center"
              mandatory
            >
              <v-item
                v-for="n in content.heroSection.cards.length"
                :value="n-1"
                :key="n-1"
              >
              <!--Switch case n, 1: text-red, 2: text-blue, 3: text-amber, 4: text-teal-->
                <v-btn
                  :class="n-1 === currentSlide ? `text-${content.heroSection.cards[n-1].color}` : 'text-background'"
                  variant="text"
                  icon="mdi-record"
                  aria-label="Skills slide switch"
                  @click="currentSlide = n-1"
                ></v-btn>
              </v-item>
            </v-item-group>
          </v-col>
        </v-row>
      </v-container>
      </v-col>
      <v-col
      class="d-flex align-start pa-0 ps-2 pb-8 pt-md-8 ps-md-0 pt-xl-16 ps-xl-0"
        cols="12"
        xs="4"
        sm="3"
        v-if="mdAndUp"
      >
      <v-img
        class="mt-16 me-8"
        alt="Free flying red bird"
        src="@/assets/images/bird8.webp"
        aspect-ratio="0.821323"
        cover
      ></v-img>
      </v-col>
      </v-row>
    </v-container>
    </v-sheet>
    <main-section :title="content.propositionSection.title" color="background" :content="content">
        <v-row class=" pa-0 pa-md-2 pa-xl-4" no-gutters>
          <v-col
            class="pa-4 pt-0 pa-md-3 ps-md-0 pe-md-0 pa-xl-6 ps-xl-0 pe-xl-0 "
            cols="12"
            sm="12"
          >
          <main-section-card color="surface" :title="content.propositionSection.cards[0].title">

            <v-container class="pa-0">
              <v-row no-gutter>
                <v-col cols="12" sm="6">
                  <div class="text-justify pe-md-2 pe-xl-4" v-html="content.propositionSection.cards[0].textHtml"></div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="ps-md-2 ps-xl-4" v-html="content.propositionSection.cards[0].textHtml2"></div>
              </v-col>
            </v-row>
            </v-container>

            <v-container class="pa-0 pt-12 pb-0">
              <v-row no-gutter>
                <v-col cols="12" sm="4" class="pt-0 pe-md-6 pt-md-2 pe-xl-12 pt-xl-3">
                  <v-card class="rounded-lg fill-height" variant="tonal" color="primary">
                  <v-card-item class="scale-title bg-primary">
                    <template v-slot:prepend>
                      <v-icon x-large>{{content.propositionSection.cards[0].headerColoredCards[0].icon}}</v-icon>
                    </template>
                    <v-card-title class="scale-title" >{{content.propositionSection.cards[0].headerColoredCards[0].title}}</v-card-title>
                  </v-card-item>
                  <v-card-text class="text-dark-surface pa-6">
                    <div class="scale-text" v-html="content.propositionSection.cards[0].headerColoredCards[0].textHtml"></div>
                  </v-card-text>
                </v-card>
                </v-col>
                <v-col  class="d-flex flex-column align-center justify-center ps-md-0 pe-md-0" cols="12" sm="4">
                <v-card class="rounded-lg fill-height" variant="tonal" color="primary">
                  <v-card-item class="scale-title bg-primary">
                    <template v-slot:prepend>
                      <v-icon>{{content.propositionSection.cards[0].headerColoredCards[1].icon}}</v-icon>
                    </template>
                    <v-card-title class="scale-title" >{{content.propositionSection.cards[0].headerColoredCards[1].title}}</v-card-title>
                  </v-card-item>
                  <v-card-text class="text-dark-surface pa-6">
                    <div class="scale-text" v-html="content.propositionSection.cards[0].headerColoredCards[1].textHtml"></div>
                  </v-card-text>
                </v-card>
              </v-col>
                <v-col  class="ps-md-6 ps-xl-12" cols="12" sm="4">
                <v-card class="rounded-lg fill-height" variant="tonal" color="primary">
                  <v-card-item class="scale-title bg-primary">
                    <template v-slot:prepend>
                      <v-icon x-large>{{content.propositionSection.cards[0].headerColoredCards[2].icon}}</v-icon>
                    </template>
                    <v-card-title class="scale-title" >{{content.propositionSection.cards[0].headerColoredCards[2].title}}</v-card-title>
                  </v-card-item>
                  <v-card-text class="text-dark-surface pa-6">
                    <div class="scale-text" v-html="content.propositionSection.cards[0].headerColoredCards[2].textHtml"></div>
                  </v-card-text>
                </v-card>

            </v-col>
              </v-row>
            </v-container>

            </main-section-card>
          </v-col>
          <v-col
            class="pa-4 pt-0 pa-md-3 ps-md-0 pa-xl-6 ps-xl-0"
            cols="12"
            sm="6"
          >
          <main-section-card color="surface" :title="content.propositionSection.cards[1].title">
            <div class="text-justify" v-html="content.propositionSection.cards[1].textHtml"></div>
            </main-section-card>
          </v-col>
          <v-col
            class="pa-4 pt-0 pa-md-3 pe-md-0 pa-xl-6 pe-xl-0"
            cols="12"
            sm="6"
          >
          <main-section-card color="surface" :title="content.propositionSection.cards[2].title">
            <div class="text-justify" v-html="content.propositionSection.cards[2].textHtml"></div>
            </main-section-card>      
          </v-col>
        </v-row>
    </main-section>
    <main-section :title="content.teamSection.title" color="surface" :content="content">

      <v-row class="pa-0 pa-md-2 pa-xl-4" no-gutters>
        <v-col
          class="pa-4 pa-md-3 ps-md-0 pa-xl-6 ps-xl-0 "
          cols="12"
          sm="6"
        >
          <main-section-card color="background" :title="content.teamSection.cards[0].title" :image="olivier">
            <div class="text-justify" v-html="content.teamSection.cards[0].textHtml"> </div>
          </main-section-card>
        </v-col>
        <v-col
          class="pa-4 pa-md-3 pe-md-0 pa-xl-6 pe-xl-0"
          cols="12"
          sm="6"
        >
          <main-section-card color="background" :title="content.teamSection.cards[1].title" :image="romain">
            <div class="text-justify" v-html="content.teamSection.cards[1].textHtml"></div>
</main-section-card>
        </v-col>
      <v-col
          cols="12"
          sm="12"
        >
      <v-row  class="pa-4 pt-4 pb-8 pa-md-3 ps-md-0 pe-md-0 pa-xl-6 ps-xl-0 pe-xl-0 " no-gutters>
        <main-section-card color="background" :title="content.teamSection.cards[2].title">
          <v-container class="pa-0 pt-0 pt-md-2 pt-xl-4">
              <v-row v-if="mdAndUp">
                <v-col cols="12" sm="4" v-for="skillSlide in content.teamSection.cards[2].skills" :key="skillSlide.title">
                  <HeaderColoredCard :skillSlide="skillSlide"></HeaderColoredCard>
                </v-col>
              </v-row>
              <v-row  v-else>
              <v-col
                cols="12"
                sm="4"
              >
                <v-window v-model="currentSkillSlide">
                  <v-window-item :value="i" v-for="(skillSlide, i) in content.teamSection.cards[2].skills" :key="i">
                    <HeaderColoredCard :skillSlide="skillSlide"></HeaderColoredCard>
                  </v-window-item>
                </v-window>
                <v-item-group
                  class="text-center"
                  mandatory
                >
                  <v-item
                    v-for="n in content.teamSection.cards[2].skills.length"
                    :value="n-1"
                    :key="n-1"
                  >
                  <!--Switch case n, 1: text-red, 2: text-blue, 3: text-amber, 4: text-teal-->
                    <v-btn
                      :class="n-1 === currentSkillSlide ? `text-${content.teamSection.cards[2].skills[n-1].color}` : 'text-dark-surface-lighten-4'"
                      variant="text"
                      density="compact"
                      icon="mdi-record"
                      aria-label="Team slide switch"
                      @click="currentSkillSlide = n-1"
                    ></v-btn>
                  </v-item>
                </v-item-group>
              </v-col>
            </v-row>
            </v-container>
          </main-section-card>
      </v-row>
      </v-col>
      </v-row>
    </main-section>
    <main-section :title="content.valuesSection.title" color="background" id="values" :content="content">
        <v-row class="pa-0 pa-md-2 pa-xl-4" no-gutters>
          <v-col
            class="pa-4 pt-0 pa-md-3 ps-md-0 pe-md-0 pa-xl-6 ps-xl-0 pe-xl-0"
            cols="12"
            sm="12"
          >
          <main-section-card color="surface" :title="content.valuesSection.cards[0].title">

            <v-container class="pa-0">
              <v-row no-gutter>
                <v-col cols="12" sm="6">
                  <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="amber-darken-3">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon>{{ content.valuesSection.cards[0].cards[0].icon }}</v-icon>
                    </template>
                    <v-card-title class="scale-title" v-html="content.valuesSection.cards[0].cards[0].titleHtml"></v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 ps-4 text-dark-surface">
                    <div class="scale-text text-justify">
                      <ValueElement class="mb-3" :plus="content.valuesSection.cards[0].cards[0].cards[0].plus" :title="content.valuesSection.cards[0].cards[0].cards[0].title" :text="content.valuesSection.cards[0].cards[0].cards[0].textHtml" />
                      <ValueElement :plus="content.valuesSection.cards[0].cards[0].cards[1].plus" :title="content.valuesSection.cards[0].cards[0].cards[1].title" :text="content.valuesSection.cards[0].cards[0].cards[1].textHtml" />
</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="blue">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon>{{ content.valuesSection.cards[0].cards[1].icon }}</v-icon>
                    </template>
                    <v-card-title class="scale-title" v-html="content.valuesSection.cards[0].cards[1].titleHtml"></v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 ps-4 text-dark-surface">
                    <div class="scale-text text-justify">
                      <ValueElement class="mb-3" :plus="content.valuesSection.cards[0].cards[1].cards[0].plus" :title="content.valuesSection.cards[0].cards[1].cards[0].title" :text="content.valuesSection.cards[0].cards[1].cards[0].textHtml" />
                      <ValueElement :plus="content.valuesSection.cards[0].cards[1].cards[1].plus" :title="content.valuesSection.cards[0].cards[1].cards[1].title" :text="content.valuesSection.cards[0].cards[1].cards[1].textHtml" />
</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            </v-container>

            </main-section-card>
          </v-col>
          <v-col
            class="pa-4 pt-0 pa-md-3 ps-md-0 pa-xl-6 ps-xl-0"
            cols="12"
            sm="6"
          >
          <main-section-card color="surface" :title="content.valuesSection.cards[1].title">
            <div class="text-justify" v-html="content.valuesSection.cards[1].textHtml"></div>
</main-section-card>
          </v-col>
          <v-col
            class="pa-4 pt-0 pa-md-3 pe-md-0 pa-xl-6 pe-xl-0"
            cols="12"
            sm="6"
          >
          <main-section-card color="surface" :title="content.valuesSection.cards[2].title">
            <ValueElement class="mb-3" :plus="content.valuesSection.cards[2].cards[0].plus" :title="content.valuesSection.cards[2].cards[0].title" :icons="content.valuesSection.cards[2].cards[0].refusals" />
            <ValueElement :plus="content.valuesSection.cards[2].cards[1].plus" :title="content.valuesSection.cards[2].cards[1].title" :text="content.valuesSection.cards[2].cards[1].textHtml" />
  
          </main-section-card>      
          </v-col>
        </v-row>
    </main-section>
    <!--
    <main-section title="Nos valeurs" color="background-lighten-5" id="Emancipation">
      <v-row class="pa-xl-4 pa-0" no-gutters>
      <v-col
            class="pa-xl-6  ps-xl-0 pa-4 pt-0"
            cols="12"
            sm="7"
          >
          <main-section-card color="surface">
            <div class="text-justify">
              Même si l’IA générative et le machine learning sont notre forte, ORIAdev s’est avant tout formée autour d’un ensemble de valeurs qui nous paraissent essentielles pour l’avenir. 
Ces valeurs comprennent des enjeux d’actualité comme <strong>l’énergie, les émissions de gaz à effet de serre ou la non prolifération des armes.</strong>
<br>Cependant, ORIAdev met aussi l’accent sur un autre défi : <strong>l’émancipation individuelle</strong>.
         </div>

                <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="red">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon x-large>mdi-human-greeting</v-icon>
                    </template>
                    <v-card-title class="scale-up-title" >Pour le bien-être <br v-if="!mdAndUp"> des utilisateurs</v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 text-dark-surface">
                    <div class="scale-text text-justify">
                      Nous travaillons sur des projets attentifs au respect de l’utilisateur.
<ValueElement :plus="false" text="Éviter les usages risquant de manipuler, d’être addictif ou de fatiguer les gens." />
<ValueElement :plus="true" text="Prêter une attention particulière à l’expérience utilisateur (UX Design) pour simplifier le quotidien" />
<ValueElement :plus="true" text="Développer des produits utiles et populaires, ne cherchant pas à accaparer l’attention." />
</div>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="amber-darken-2">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon x-large>mdi-scale-balance</v-icon>
                    </template>
                    <v-card-title class="scale-up-title" >Pour l’égalité des chances</v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 pt-2 text-dark-surface">

                    <div class="scale-text text-justify">
                      La technologie a un potentiel immense pour démocratiser les opportunités.
<ValueElement :plus="true" text="Permettre à chacun de multiplier ses compétences grâce à l’IA générative" />
<ValueElement :plus="true" text="Rendre accessibles des services trop onéreux pour certains (soutien scolaire, conseil juridique…)" />
</div>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="blue">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon x-large>mdi-domain</v-icon>
                    </template>
                    <v-card-title class="scale-up-title" >Pour l'épanouissement <br v-if="!mdAndUp"> au travail</v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 pt-2 text-dark-surface">
                    <div class="scale-text text-justify">
                      Considérant que nous passons environ 80 000 heures de notre vie à travailler, il est essentiel d'éviter les tâches pénibles, de trouver du sens dans nos responsabilités, et de maintenir un bon équilibre entre vie professionnelle et personnelle.

                      <ValueElement :plus="true" text="Automatisation des tâches rébarbative" />
                      <ValueElement :plus="true" text="Copilote, compagnon de brainstorming" />


</div>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-xl fill-height pa-2 ps-0" variant="text" color="teal">
                  <v-card-item class="scale-title">
                    <template v-slot:prepend>
                      <v-icon x-large>mdi-home-group</v-icon>
                    </template>
                    <v-card-title class="scale-up-title" >Pour la société et l’avenir</v-card-title>
                  </v-card-item>
                  <v-card-text class="pa-0 pt-2 text-dark-surface">
                    <div class="scale-text text-justify">Nous croyons fermement qu’une société fonctionnelle et prospère repose sur l’émancipation de ses membres. 
<ValueElement :plus="false" text="Refus de génération de contenu automatisée présentant comme vrai des choses non vérifiée (fake news)" />
<ValueElement :plus="false" text="Pour la planète, réduire les achats compulsifs" />
<ValueElement :plus="true" text="Nous soutenons des projets susceptibles d’améliorer la société et de renforcer les fonctionnements positifs pour la démocratie. (Rendre les gens plus actifs, moins passifs)" />
</div>
                  </v-card-text>
                </v-card>
            </main-section-card>
          </v-col>
          <v-col
            class="pa-xl-6 pe-xl-0 pa-4 pt-0"
            cols="12"
            sm="5"
          >
          <v-row no-gutters>
            <main-section-card color="dark-surface">
            
            <span class="text-background scale-up-text">
                      <strong>Qu’est ce que nous entendons par émancipation individuelle ?</strong></span>
                      <div class="text-background pt-4 text-justify">
Si le monde a connu des <a href="https://ourworldindata.org/a-history-of-global-living-conditions" target="_blank">progrès spectaculaires ces derniers siècles</a>, il semblerait que plus récemment la technologie se développe aussi <a href="https://www.humanetech.com/key-issues" target="_blank"> au détriment des individus</a>. 
Nous sommes soucieux de <strong> remettre l’humain au centre</strong> : favoriser l’autonomie de chacun et simplifier le quotidien.
<br><br>
émancipé(e) : qui est libre de toute dépendance, qui a le choix, qui peut décider de sa vie, gagner en responsabilité et avoir un accès non restreint à des contacts humains.

                      </div>
            </main-section-card> 
          </v-row>
          <v-row no-gutters v-if="mdAndUp">
                          <v-img
                          class="mt-16"
                          style = "mix-blend-mode: darken;"
              src="https://files.oaiusercontent.com/file-6peIuP5ItEto78r6AMIhJwHs?se=2024-04-22T11%3A30%3A07Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Db3170c66-9a4e-439b-a5f9-2fcd1c2056fd.webp&sig=8kF%2B71ZD7qgv6oEBzhGQkqicoCWvARGOkXZsnu4N2KM%3D"
              cover
              ></v-img>

            </v-row>
          </v-col>
        </v-row>
    </main-section>-->
    
    <v-sheet color="dark-surface" textcolor="dark-surface-lighten-3">
      <!--Footer-->
      <v-row class="pa-xl-4 pa-0" no-gutters>
        <v-col
          class="pa-0"
          cols="12"
          sm="6"
        >
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          sm="3"
        >
          <main-section-card color="dark-surface" title="Contact" textcolor="dark-surface-lighten-3">
            <a href="mailto:contact@oriadev.com">contact@oriadev.com</a>
            <div>16 Rue de Strasbourg</div>
            <div>38000 Grenoble</div>
          </main-section-card>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          sm="3">
          <main-section-card color="dark-surface" title="Langues" textcolor="dark-surface-lighten-3" class="d-flex flex-column">
            <div @click="changeLocale('fr')" class="link">Français</div>
            <div @click="changeLocale('en')" class="link">English</div>
          </main-section-card>
        </v-col>
      </v-row>
      <!--
      <v-row>
        <a href="javascript:openAxeptioCookies()">
          Click here to update your cookies settings 
        </a>
      </v-row>-->


    </v-sheet>
</template>

<script>
import MainSection from '@/components/MainSection.vue'
import MainSectionCard from '@/components/MainSectionCard.vue'
import ColoredCard from '@/components/ColoredCard.vue'
import HeaderColoredCard from '@/components/HeaderColoredCard.vue'
import ValueElement from '@/components/ValueElement.vue'
import romain from '@/assets/images/romain.webp';
import olivier from '@/assets/images/olivier.webp';

import { useDisplay } from 'vuetify'
import { useGoTo } from 'vuetify'

import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import {mapActions} from 'vuex';

// Register the TextPlugin with GSAP

// Destructure only the keys you want to use


export default {
  setup() {
    gsap.registerPlugin(TextPlugin);
    const { xs, mdAndUp } = useDisplay()
    const goTo = useGoTo()
    return {
      xs, mdAndUp, goTo
    }
  },
  components: {
    MainSection,
    MainSectionCard,
    ColoredCard,
    HeaderColoredCard,
    ValueElement
  },
  props: {
    content: Object
  },
  data: () => ({
    romain,
    olivier,
    currentSlide: 0,
    timeline: null,
    skillsSlides: [
      {
        title: 'Développement software',
        color: 'red-lighten-1',
        icon: 'mdi-code-braces',
        skills: ['Python', 'Django', 'Pandas', 'Javascript', 'Vue', 'React']
      },
      {
        title: 'IA Générative',
        color: 'teal-lighten-1',
        icon: 'mdi-robot-industrial',
        skills: ['LLMs', 'Prompt engineering', 'Fine tuning', 'RAGs', 'Knowledge graphs', 'Agents']
      },
      {
        title: 'Data science',
        color: 'orange-lighten-1',
        icon: 'mdi-chart-scatter-plot-hexbin',
        skills: ['Machine Learning', 'Computer Vision', 'Systèmes de Recommendation', 'Dashboards et visualisation de données', 'Web Scraping', 'Système d\'expert', 'Deep Learning']
      },
      {
        title: 'Cloud AWS',
        color: 'blue-lighten-1',
        icon: 'mdi-cloud-outline',
        skills: ['Serverless AWS (Lambdas/SQS/CloudWatch)', 'Sécurité', 'Hébergement', 'Stockage', 'VPC', 'Docker', 'Terraform']
      },
      {
        title: 'UI / UX Design',
        color: 'pink-lighten-2',
        icon: 'mdi-pencil-ruler',
        skills: ['Product design', 'Visual design', 'User experience', 'Prototypage']
      },
      {
        title: 'Méthodes agile',
        color: 'indigo-lighten-1',
        icon: 'mdi-swap-horizontal-variant',
        skills: ['Scrum', 'Kanban', 'Rétrospectives', 'Sprints', 'User stories', 'Daily standup', 'Démo', 'Définition de \'Done\'']
      }

    ],
  //<v-icon>mdi-cigar-off</v-icon> 
  //<v-icon>mdi-cctv-off</v-icon>
  //<v-icon>mdi-fingerprint-off</v-icon>
  //<v-icon>mdi-cart-off</v-icon>
  //<v-icon>mdi-virus-off</v-icon>
  //<v-icon>mdi-glass-mug-off</v-icon> 
  //<v-icon>mdi-slot-machine</v-icon>
  //<v-icon>mdi-gas-station-off</v-icon> 
  //<v-icon>mdi-pistol</v-icon>
    currentSkillSlide: 0,
  }),
  mounted() {
    this.animateWords(this.content.heroSection.titleWords);
  },
  watch: {
    'content.heroSection.titleWords': {
      handler(newValue) {
        this.restartAnimation(newValue);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(['changeLocale']),
    sendEmail() {
        const email = 'contact@oriadev.com';
        const subject = encodeURIComponent('Demande de collaboration - [Nom de l\'entreprise]');
        const body = encodeURIComponent(
            "Bonjour,\n\n" +
            "Je me permets de vous contacter au nom de [Nom de l'entreprise] concernant une éventuelle collaboration. Nous sommes intéressés par les services que votre entreprise offre et nous aimerions explorer les possibilités de travailler ensemble.\n\n" +
            "Ci-dessous, vous trouverez un bref résumé de notre projet ainsi que nos coordonnées pour toute correspondance future:\n\n" +
            "Nom de l'entreprise: [Nom de l'entreprise]\n" +
            "Résumé du projet: [Brève description du projet]\n" +
            "Détails supplémentaires: [Autres informations ou exigences spécifiques]\n\n" +
            "Nous sommes impatients de discuter plus en détail de la manière dont nous pouvons collaborer efficacement. Veuillez nous indiquer une date et une heure qui vous conviendraient pour une rencontre téléphonique ou en personne.\n\n" +
            "Merci beaucoup pour votre attention. Nous espérons avoir de vos nouvelles très bientôt.\n\n" +
            "Cordialement,\n\n" +
            "[Nom]\n" +
            "[Position dans l'entreprise]\n" +
            "[Téléphone]\n" +
            "[Email de contact]"
        );
        window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
    restartAnimation(words) {
      if (this.timeline) {
        this.timeline.kill(); // Stop the current timeline
      }
      this.animateWords(words);
    },
    animateWords(words) {
      const tl = gsap.timeline({repeat: -1, repeatDelay: 1});
      const newWords = words.slice(1).concat(words[0]); // Rearrange words
      newWords.forEach(word => {
        tl.to('#animated-word', {
          duration: 0.5,
          text: word,
          ease: 'none',
          delay: 1.5 // Delay before changing to the next word
        });
      });
      this.timeline = tl;
    }
  }
}
</script>

<style>
.background-video {
  width: 100vw; 
  height: 50vw;
  top: 0;
  left: 0;
  /*border-bottom: solid 5px rgba(var(--v-theme-primary), 0.8);*/
}

.slider-thumb {
	position: absolute;
	content: "";
	left: -90px;
	top: -70px;
	border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
	will-change: border-radius, transform, opacity;
	animation: sliderShape 20s linear infinite;
	display: block;
	-webkit-animation: sliderShape 20s linear infinite;
}
@keyframes sliderShape{
  0%,100%{
  border-radius: 0 58% 60% 30% / 0 45% 55% 55%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  34%{
      border-radius: 0 30% 46% 54% / 0 29% 71% 70%;
    transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  }
  50%{
	border-radius: 0 47% 62% 35% / 0 45% 60% 66%;
    transform: translate3d(0,0,0) rotateZ(0.01deg);
  }
  67%{
    border-radius: 0 60% 60% 100% / 0 100% 60% 60% ;
    transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  }
}
.hero-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300; /* Light */
    font-size: 21px; /* Adjust as needed */
    /*Unselectable text*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.hero-quote {
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 60px; /* Adjust as needed */
    /*Reduce interline*/
    line-height: 1.2;
    /*Unselectable text*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.scribble {
  font-family: 'Caveat', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: rgba(var(--v-theme-dark-surface), 1);
  text-decoration: none;
}
.link:hover {
  color: rgba(var(--v-theme-primary), 1);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .scribble{
    font-size: 1.4rem;
  }
}

/* Adjust hero quote size for smaller screens */
@media screen and (max-width: 600px) {
  .hero-quote {
    font-size: 40px;
  }
}

.main-card {
  height:220px;
}

/* Adjust card size for smaller screens */
@media screen and (max-width: 600px) {
  .main-card {
    height: 165px;
  }
}


.scale-up-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
  font-size: 1.3rem; /* Adjust the size as needed */
}

.scale-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
  font-size: 1.1rem; /* Adjust the size as needed */
}
.arrow-icon {
  font-family: 'Caveat', sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
}

/* Adjust title size for smaller screens */
@media screen and (max-width: 600px) {
  .scale-up-title {
    font-size: 1.1rem;
  }
}

/* Adjust title size for smaller screens */
@media screen and (max-width: 600px) {
  .scale-title {
    font-size: 1.1rem;
  }
}

.scale-up-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300; /* Light */
    font-size: 1.1rem; /* Adjust the size as needed */
}

.scale-up-text{
  font-family: 'Raleway', sans-serif;
  font-size: 1rem; /* Adjust the size as needed */
  font-weight: 500;
  line-height: 1.4;
}

.scale-text{
  font-family: 'Raleway', sans-serif;
  font-size: 0.9rem; /* Adjust the size as needed */
  font-weight: 500;
  line-height: 1.4;
}


/* Adjust text size for smaller screens */
@media screen and (max-width: 600px) {
  .scale-up-text, .scale-text {
    font-size: 0.9rem;
  }
}

.scale-up-icon {
  font-size: 3.1rem; /* Adjust the size as needed */
}

/* Adjust icon size for smaller screens */
@media screen and (max-width: 600px) {
  .scale-up-icon {
    font-size: 2.2rem;
  }
}

.list-padding li{
  margin-left: 15px;
  padding-left: 5px;
  padding-top: 10px;
}

.section-title {
    font-family: 'Caveat', sans-serif;
    font-weight: 700;
    font-size: 40px; /* Adjust as needed */
}

/* Adjust title size for smaller screens */
@media screen and (max-width: 600px) {
  .section-title {
    font-size: 35px;
  }
}

.border-bottom-primary {
    border-bottom: solid 4px rgba(var(--v-theme-primary), 0.8);
}
.border-bottom-grey {
    border-bottom: solid 4px rgba(var(--v-theme-dark-surface-lighten-2), 0.5);
}

.v-timeline--horizontal.v-timeline .v-timeline-item:nth-child(2n+1) .v-timeline-item__body{
  align-self: flex-start;
}
li {
  margin-left: 32px;
}
a {
  color: rgba(var(--v-theme-primary), 1);
  font-weight: 700;
}
</style>