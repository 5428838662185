<template>
    <v-dialog v-model="isVisible" persistent max-width="600px">
      <v-card class="pa-md-4 rounded-lg rounded-md-xl" color="dark-surface">
        <v-card-title>
          Contactez-nous !
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
            <v-text-field
              v-model="form.name"
              :rules="nameRules"
              label="Nom et prénom"
              prepend-icon="mdi-account"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.company"
              :rules="nameRules"
              label="Entreprise"
              prepend-icon="mdi-domain"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              label="Email"
              prepend-icon="mdi-email"
              required
            ></v-text-field>
            <v-textarea
              v-model="form.message"
              :rules="messageRules"
              label="Message"
              hint="Pourriez-vous partager quelques points essentiels de votre projet afin d'accélérer notre prochaine conversation ?"
              persistent-hint
              prepend-icon="mdi-message-text"
              required
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!valid" variant="tonal" color="primary" type="fill" @click="submitForm">Envoyer</v-btn>
          <v-btn @click="closePopup">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import emailjs from 'emailjs-com';
  import { mapState, mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        valid: false,
        form: {
          name: '',
          company: '',
          email: '',
          message: ''
        },
        nameRules: [
          v => !!v || 'Le nom est requis',
          v => (v && v.length >= 3) || 'Le nom doit contenir au moins 3 caractères'
        ],
        emailRules: [
          v => !!v || 'L\'email est requis',
          v => /.+@.+\..+/.test(v) || 'E-mail invalide'
        ],
        messageRules: [
          v => !!v || 'Le message est requis',
          v => (v && v.length >= 10) || 'Le message doit contenir au moins 10 caractères'
        ]
      };
    },
    computed: {
      ...mapState({
        isVisible: state => state.isContactPopupVisible
      })
    },
    methods: {
      ...mapActions(['hideContactPopup']),
      submitForm() {
        if (this.valid) {
          this.sendEmail();
        }
      },
      sendEmail() {
        const serviceID = 'service_iah44ea';
        const templateID = 'template_tjrj1fu';
        const userID = '9rMQdxWfY1NCeF0C3';
  
        emailjs.send(serviceID, templateID, this.form, userID)
          .then(response => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Email envoyé avec succès !');
            this.resetForm();
            this.closePopup();
          }, error => {
            console.log('FAILED...', error);
            alert('Échec de l\'envoi de l\'email.');
          });
      },
      resetForm() {
        this.form = {
          name: '',
          company: '',
          email: '',
          message: ''
        };
        this.$refs.form.reset();
      },
      closePopup() {
        this.hideContactPopup();
      }
    }
  };
  </script>
  