import { createStore } from 'vuex';

const supportedLocales = ['en', 'fr']; // List all supported locales

function getInitialLocale() {
  // Attempt to retrieve the stored language setting
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale && supportedLocales.includes(savedLocale)) return savedLocale;

  // Fallback to the browser's language, defaulting to 'en' if not handled
  const browserLocale = navigator.language.slice(0, 2);
  if (supportedLocales.includes(browserLocale)) return browserLocale;
  return 'fr'; // Default to French
}

export default createStore({
  state: {
    locale: getInitialLocale(),
    content: require(`@/content/${getInitialLocale()}.json`),
    isContactPopupVisible: false,
  },
  mutations: {
    setLocale(state, locale) {
      if (supportedLocales.includes(locale)) {
        state.locale = locale;
        state.content = require(`@/content/${locale}.json`);
        localStorage.setItem('locale', locale); // Save the current language setting
      } else {
        console.error(`Locale ${locale} is not supported. Falling back to English.`);
        state.locale = 'en';
        state.content = require('@/content/en.json');
        localStorage.setItem('locale', 'fr'); // Fallback to French
      }
    },
    SHOW_CONTACT_POPUP(state) {
      state.isContactPopupVisible = true;
    },
    HIDE_CONTACT_POPUP(state) {
      state.isContactPopupVisible = false;
    }
  },
  actions: {
    changeLocale({ commit }, locale) {
      commit('setLocale', locale);
    },
    showContactPopup({ commit }) {
      commit('SHOW_CONTACT_POPUP');
    },
    hideContactPopup({ commit }) {
      commit('HIDE_CONTACT_POPUP');
    }
  }
});
