<template>
    <v-card class="rounded-lg fill-height" style="min-height: 290px" variant="tonal" :color="skillSlide.color">
        <v-card-item :class="`scale-title bg-${skillSlide.color} text-surface`">
        <template v-slot:prepend>
            <v-icon large>{{ skillSlide.icon }}</v-icon>
        </template>
        <v-card-title class="scale-title" >{{ skillSlide.title }}</v-card-title>
        </v-card-item>
        <v-card-text class="text-dark-surface pa-6">
        <div class="scale-text" v-for="skill in skillSlide.skills" :key="skill">
            {{ skill }}
        </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'SkillCard',
    props: {
        skillSlide: Object,
    },
    data: () => ({
        //
    }),
}
</script>