<template>
    <v-container :class="`bg-${color} pa-0 ma-0 pa-md-8 pt-md-4 pb-md-4 pa-xl-16 pt-xl-4 pb-xl-4 fluid text-${textcolor}`" style="min-width:100vw;">
    <v-row class="pa-0 pa-md-8 pt-md-4 pb-md-4 pa-xl-16 pt-xl-4 pb-xl-4 " no-gutters>
      <v-col
        class="pa-0 pa-md-2 pe-md-0 pa-xl-4 pe-xl-0"
        cols="12"
        sm="3"
      >
        <v-row class="pa-4 pe-md-0 d-flex flex-wrap justify-right" no-gutters>
          <h1 class="section-title pa-4 pa-md-0 pa-xl-4 ps-0 pe-0" :v-html="title">{{ title }}</h1>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="9"
        class="d-flex flex-column justify-center align-center"
      >
      <v-container class="pa-0 pa-md-2 pa-xl-4 ps-xl-0 fluid">
            <slot></slot>
      </v-container>

      <v-btn aria-label="Contact button" color="primary" class="rounded-lg ma-0 mb-4" style="width: fit-content;" @click="showContactPopup">{{ content.metadata.contactButton }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'MainSection',
  props: {
    color: String,
    title: String,
    content: Object,
    textcolor: {
      type: String,
      default: 'dark-surface',
    }
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(['showContactPopup'])
  }
}
</script>