<template>
    <v-card :class="`main-card bg-${slide.color} ma-0 mb-4 ma-md-2 ma-xl-4 rounded-xl elevation-0 position-relative text-dark-surface`">
                <v-icon class="scale-up-icon text-surface pb-0 position-absolute" style="right: 16px; bottom: 16px">{{ slide.icon }}</v-icon>
                <v-card-item class=" pa-4 pb-4 pa-md-6 pb-md-3 pa-xl-8 pb-xl-4">
                <v-card-title class="scale-up-title" style="z-index: 20;">{{ slide.title }}</v-card-title>

                </v-card-item>
              <v-card-text class="pa-4 pt-0 pa-md-6 pb-md-0 pe-md-8 pt-md-0 pa-xl-8 pe-xl-16 pt-xl-0" >
                <div class="scale-up-text" v-html="slide.subtitle"></div>
              </v-card-text>
            </v-card> 
</template>

<script>
export default {
    name: 'ColoredCard',
    props: {
        slide: Object,
    },
    data: () => ({
        //
    }),
}
</script>