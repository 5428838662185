// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { fr } from 'vuetify/locale';

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#E8E8E8',
    surface: '#F4F4F2',
    'dark-surface': '#282e36',
    'dark-surface-lighten-2': '#5a6677',
    'dark-surface-lighten-3': '#989594',
    'dark-surface-lighten-4': '#B8B5B4',
    'background-darken-3': '#3A4042',
    primary: '#d93c50',
    secondary: '#E8E8E8',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  variables: {
    'border-color': '#7e3636',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}

export const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'fr',
    messages: { fr },
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
})
