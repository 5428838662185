<template>
  <v-app>
    <v-main class="d-flex flex-column position-relative" style="height: 100vh;">
    <nav-bar-home :content="content"></nav-bar-home>
    <div class="flex-1-1" id="mainContainer" style="overflow-y: auto; overflow-x: hidden;">
      <router-view :content="content" />
    </div>
    <ContactForm />
      
    </v-main>
  </v-app>
</template>

<script>
import NavBarHome from './components/NavBarHome.vue';
import ContactForm from './components/ContactForm.vue';
import { mapState } from 'vuex';
export default {
  name: 'App',
  components: {
    NavBarHome,
    ContactForm
  },
        data: () => ({
          tab: 1,
        }),
        computed: {
          ...mapState(['content']),
        },
        watch: {
        tab (val) {
            if (val === 1) {
            this.$router.push({ name: 'home' })
            } else if (val === 2) {
            this.$router.push({ name: 'blog' })
            }
        },
        $route (to) {
            if (to.name === 'home') {
            this.tab = 1
            } else if (to.name === 'blog') {
            this.tab = 2
            }
        }
        },
        mounted() {
          this.loadAxeptioSDK();
        },
        methods: {
          loadAxeptioSDK() {
            const axeptioSettings = {
              clientId: "6639dd8ca15e323490f1111b",
              cookiesVersion: "oriadev-fr-EU",
              googleConsentMode: {
                default: {
                  analytics_storage: "denied",
                  ad_storage: "denied",
                  ad_user_data: "denied",
                  ad_personalization: "denied",
                  wait_for_update: 500
                }
              }
            };

            window.axeptioSettings = axeptioSettings;

            const scriptTag = document.createElement('script');
            scriptTag.async = true;
            scriptTag.src = "//static.axept.io/sdk.js";

            document.body.appendChild(scriptTag);
          }
        }
}
</script>
<style>
html {
  scroll-behavior: smooth;
  overflow: hidden !important;
}

body {
  font-family: 'Roboto', sans-serif;
  color: var(--v-theme-dark-surface);
}
.backdrop-blur {
    backdrop-filter: blur(12px);
    background-color: #E8E8E8bb;
}
</style>
