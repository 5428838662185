<template>
    <div class="d-flex align-center pa-4 rounded-lg text-left" style="border: 3px #E8E8E8 solid;">
        <v-icon class="text-light-green-lighten-1 pe-4" v-if="plus">mdi-plus-box</v-icon>
        <v-icon class="text-red-lighten-2 pe-4" v-else>mdi-minus-box</v-icon>
        <div>
        <div class="pb-1" :class="plus ? 'text-light-green-darken-2':'text-red-darken-2'"><strong>{{ title }}</strong></div>
        <span>{{ text }}</span>
        <div class="d-flex flex-wrap justify-center align-center">
            <div class="d-flex flex-column align-center justify-center text-dark-surface pa-2" v-for="curicon in icons" :key="curicon.text">
                <v-icon>{{ curicon.icon }}</v-icon>
                <span> {{curicon.text}}</span>
            </div>
        </div>

        </div>
    
    </div>
</template>
<script>
export default {
    props: {
        plus: Boolean,
        title: String,
        text: String,
        icons: Object
    }
}
</script>

